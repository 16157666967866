@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

/*  Media BreakPoints; */
$xs: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1119.98px;
/*                    */

/* COLORS ==== FEEL FREE TO ADD MORE HERE & DONT FORGET TO ADD THEM IN THE CONFIG FILE UNDER COLORS + THE MUI PALETTE APP */
$BLACK_01: #333333; //migrated
$GRAY_01: #9e9e9e; // migrated
$GRAY_05: #f7f7f7; // migrated
$GRAY_03: #dadada; // migrated
$GRAY_04: #ececec; // migrated
$GRAY_02: #c6c6c6; // migrated
$DARK_BLUE: #0c1432; // migrated
$NEW_PRIMARY: #4a3f72; // migrated
$SUCCESS: #44b76c; //migrated
$DANGER: #ee534c; // migrated
$WARNING: #f88115; // migrated
$WHITE: #ffff; //migrated
$PRIMARY_PAPER: #f7f7f7; // migrated
$CHARCOAL: #212121; // migrated
$PRIMARY: #448fea; //migrated
$PRIMARY_02: #9ac8ff; // migrated
$SUCCESS_02: #4aa321; // migrated
$SECONDARY: #f50057;

/*        */

html,
body,
.App,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
