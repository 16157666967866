@import '../../../index.scss';
header {
  background-color: $GRAY_05;
  height: fit-content;
  min-height: 48px;
  display: flex;
  width: 100%;
  .avatar {
    margin-right: 1em;
  }
}
