.wrapper-main {
  display: grid;
  grid-template-columns: 12em 1fr;
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
  .wrapper-content {
    height: 100vh;
    grid-column: -2;
    display: grid;
    grid-template-rows: 48px 1fr;
  }
}
