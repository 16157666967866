@import '../../../../index.scss';

.dataSetInfo {
  display: flex;
  margin: 1em 0;
  padding-left: 0.6em;
  align-items: center;
}

.buttonStyle-1 {
  margin-left: 1em !important;
}

@media (max-width: $sm) {
  .dataSetInfo {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonStyle-1 {
    margin-top: 1em !important;
  }
}
