main {
  padding: 5em 0 0.5em 0;
  overflow: auto;
  height: -webkit-fill-available;
}

section {
  min-width: 25em;
  padding-left: 2.5em;
  padding-right: 2.5em;
}
