@import '../../../../index.scss';

.container {
  width: 40%;
  margin-top: 2em;
  min-width: 20em;
  p {
    color: $GRAY_01;
  }
  .dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: dotted;
    padding: 2.3em 0;
    border-radius: 0.5em;
    border-color: $GRAY_01;
  }
}
