@import '../../../index.scss';

.wrapper-nav {
  display: grid;
  position: fixed;
  grid-template-rows: 10em 1fr;
  background-color: $NEW_PRIMARY;
  max-width: 12em;
  min-width: 12em;
  height: 100%;

  .logo-wrapper {
    text-align: center;
    align-self: center;
    cursor: pointer;
    svg {
      margin-top: 2em;
      transform: scale(0.5);
    }
  }
  .list-wrapper {
    display: grid;
    grid-template-columns: 5% 90% 5%;
    grid-template-rows: 5em 2.5em 1fr;
    grid-column-gap: 0.5em;
    max-height: 100vh;
    h4:first-child {
      grid-column: 2;
      grid-row: 1;
      font-size: 17px;
      font-weight: 700;
      color: white;
      line-height: 19px;
      align-self: center;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    h4:nth-child(2) {
      grid-row: 2;
      grid-column: 2;
      font-style: normal;
      font-weight: normal;
      font-size: 18.5px;
      line-height: 19px;
      color: $GRAY_04;
      margin: 0;
    }

    ul {
      grid-column: 2;
      height: 80%;
      grid-row: 3;
      padding: 0;
      overflow: hidden auto;
      width: 100%;
      position: relative;
      scrollbar-width: thin;
      h4 {
        text-align: left;
        color: $GRAY_04;
        margin-bottom: 1em;
        position: sticky;
        top: 0;
        &:hover {
          opacity: 1;
        }
      }

      div {
        padding: 0.5em 0em;
        color: $GRAY_04;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #071855;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2852e7;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $PRIMARY;
  }
  // This is for firefox issues...
  scrollbar-color: #2852e7 $DARK_BLUE;
  scrollbar-width: thin;
}
