form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2em;
}

.globalStatusContainer {
  margin-top: 1em;
  display: flex;
  p {
    margin-left: 0.6em;
    align-self: center;
    margin-bottom: 0;
    font-size: small;
  }
}
